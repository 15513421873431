<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userNom="userNom"
          :userPrenom="userPrenom"
          :userPhoto="userPhoto"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div
                  class="page-title"
                  v-if="this.$route.name === 'genererFactureDefinitive'"
                >
                  Générer Facture Definitive
                </div>
                <div
                  class="page-title"
                  v-if="this.$route.name === 'genererFactureAcompte'"
                >
                  Générer Facture Acompte
                </div>
                <div
                  class="page-title"
                  v-if="this.$route.name === 'genererFactureSolde'"
                >
                  Générer Facture Solde
                </div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a
                    class="parent-item"
                    :href="'/dashboard-saf'"
                    >Tableau de Bord</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">
                  Valider réservation &nbsp;<a class="parent-item"></a>&nbsp;
                </li>
              </ol>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Information</header>
                  <button
                    id="panel-button"
                    class="mdl-button mdl-js-button mdl-button--icon pull-right"
                    data-upgraded=",MaterialButton"
                  >
                    <i class="material-icons">more_vert</i>
                  </button>
                  <ul
                    class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                    data-mdl-for="panel-button"
                  >
                    <li class="mdl-menu__item">
                      <i class="material-icons">assistant_photo</i>Action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">print</i>Another action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">favorite</i>Something else here
                    </li>
                  </ul>
                </div>

                <div class="card-body row">
                <!--     <div class="col-lg-12 p-t-20">
                        <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                        >
                        <label
                            class="mdl-textfield__label"
                            for="txtFirstName"
                            v-show="!form.objet"
                            >Objet</label
                        >
                        <input
                            class="mdl-textfield__input"
                            type="text"
                            id="txtFirstName"
                            v-model="form.objet"
                            @input="validatedata('objet')"
                        />
                        <span class="erreur">{{ this.objet_erreur }}</span>
                        </div>
                    </div> -->
                  <!-- <div class="col-lg-12 p-t-20">
                        <div
                            class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                            <label for="list5" class="mdl-textfield__label" v-show="!form.montant_payer">Montant</label>
                            <select class="mdl-textfield__input" id="list5" readonly tabIndex="-1"
                                v-model="form.montant_payer" @change="validatedata('montant_payer')">
                                <option value="100">100%</option>
                                <option value="70">70%</option>
                                <option value="30">30%</option>
                            </select>
                            <span class="erreur">{{ montant_payer_erreur }}</span>
                        </div>
                    </div> -->

                  <div class="col-lg-12 p-t-20">
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                    >
                      <label
                        class="mdl-textfield__label"
                        for="txtFirstName"
                        v-show="!form.montant_payer"
                        >Montant</label
                      >
                      <input
                        class="mdl-textfield__input"
                        type="number"
                        id="txtFirstName"
                        v-model="form.montant_payer"
                        @input="validatedata('montant_payer')"
                      />
                      <span class="erreur">{{
                        this.montant_payer_erreur
                      }}</span>
                    </div>
                  </div>

                  <div class="col-lg-12 p-t-20">
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                    >
                      <label
                        class="mdl-textfield__label"
                        for="dateOfBirth"
                        v-show="!form.date_facture"
                        >Date</label
                      >
                      <flat-pickr
                        v-model="form.date_facture"
                        class="mdl-textfield__input"
                        @input="validatedata('date_facture')"
                      ></flat-pickr>
                      <span class="erreur">{{ this.date_facture_erreur }}</span>
                    </div>
                  </div>

                  <div class="col-lg-12 p-t-20 text-center">
                    <button
                      type="submit"
                      v-if="!this.editModal"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                      @click.prevent="validerAvantAjout()"
                    >
                      Enregistrer
                    </button>

                    <button
                      type="submit"
                      v-if="this.editModal"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                      @click.prevent="validerAvantAjout()"
                    >
                      Modifier
                    </button>

                    <button
                      type="button"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                      @click="goBack()"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <appafterContent />
    </div>
    <appfooter />
  </div>
</template>
<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import Swal from "sweetalert2";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";
import IdleTimeoutMixin from "@/IdleTimeoutMixin.js";
import router from "../../router.js";

export default {
  mixins: [IdleTimeoutMixin],
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    flatPickr,
  },
  props: ["saf"],
  name: "createReservationCompenent",

  data() {
    return {
      salles: [],
      reservations: [],
      form: new Form({
        id_user: "",
        objet: "",
        montant_payer: "",
        date_facture: "",
        id_location: "",
      }),
      date_facture_erreur: "",
      montant_payer_erreur: "",
      objet_erreur: "",

      etatForm: false,
      editModal: false,
      reservationId: null,
      factureId: null,
    };
  },
  computed: {
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  mounted() {
    bus.on("reservationModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.monterToupdate(eventData.reservation);
    });
    this.reservationId = this.$route.params.id;
    this.factureId = this.$route.params.idlocation;
    /* console.log(this.reservationId)
       console.log(this.$route.params.idlocation) */
  },

  methods: {
    ...mapActions(["login", "logout"]),
    async soumettre() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();

      formdata.append("date_facture", this.form.date_facture);
      formdata.append("montant_payer", this.form.montant_payer);
      formdata.append("id_user", this.userIdrole);
      formdata.append("objet", this.form.objet);
      formdata.append("id_location", this.factureId);
      formdata.append("id_reservation", this.reservationId);

      if (this.$route.name === "genererFactureDefinitive") {
        formdata.append("type", "Definitive");
      } else if (this.$route.name === "genererFactureAcompte") {
        formdata.append("type", "Acompte");
      } else if (this.$route.name === "genererFactureSolde") {
        formdata.append("type", "Solde");
      }

      try {
        const reservation_store = await axios.post("/facture/store", formdata, {
          headers,
        });
        if (reservation_store.data == 401) {
          showDialog3("Cette facture à été déjà réserver");
        }
        showDialog6("Facture ajoutée avec succès");
        bus.emit("factureAjoutee;");
        this.resetForm();
        setTimeout(() => {
          //window.location.href = "/liste-facture";
          if (this.$route.name === "genererFactureDefinitive") {
            bus.emit("changerOngletActif", "tab2");
          }
          if (this.$route.name === "genererFactureAcompte") {
            bus.emit("changerOngletActif", "tab3");
          }
          if (this.$route.name === "genererFactureSolde") {
            bus.emit("changerOngletActif", "tab4");
          }
          router.push({ name: "listefacture" });
        }, 1500);
      } catch (e) {
        //console.log(e.request.data)
        if (e.request.status === 404) {
          showDialog3("Une erreur est survenue lors de l'enregistrement");
        }
      }
    },

    validerAvantAjout() {
      const isVerifIdValid = this.validatedataOld();
      if (isVerifIdValid === true) {
        this.etatForm = false;
        this.editModal = false;
        console.log("erreur");
        return 0;
      } else {
        if (this.editModal === true) {
          this.etatForm = true;

          this.editModal = false;
        } else {
          this.soumettre();
          this.etatForm = true;
          this.editModal = false;

          console.log("Tokkos");
        }
      }
    },
    goBack() {
      // Utilisez la méthode Vue.js $router pour revenir en arrière
      this.resetForm();
      this.$router.go(-1);
    },
    resetForm() {
      this.form.date_facture = null;
      this.form.id_salle = "";
      this.form.objet = "";
      this.form.montant_payer = "";

      (this.date_facture_erreur = ""),
        (this.date_facture_erreur = ""),
        (this.id_salle_erreur = ""),
        (this.montant_payer_erreur = ""),
        (this.objet_erreur = ""),
        (this.editModal = false);

      const eventData = {
        editModal: false,
      };
      bus.emit("reservationDejaModifier", eventData);
    },

    validatedata(champ) {
      switch (champ) {
        case "date_facture":
          this.date_facture_erreur = "";
          // Effectuez la validation pour le champ 'nom'
          if (this.form.date_facture === "") {
            this.date_facture_erreur = "Ce champ est obligatoire";
            //this.coloration_erreur_rouge(this.nom_service_erreur);
            return true;
          }
          break;

       /*  case "objet":
          //pour direction
          this.objet_erreur = "";
          if (this.form.objet === "") {
            this.objet_erreur = "Ce champ est obligatoire'";
            return true;
          }
          break; */

        case "montant_payer":
          this.montant_payer_erreur = "";
          //Vérification de montant_payer
          if (this.form.montant_payer === "") {
            this.montant_payer_erreur =
              "Vous avez oublié de sélectionner le montant ";
            i = 1;
            return true;
          }
          break;

        default:
          break;
      }
      return false;
    },

    validatedataOld() {
      this.date_facture_erreur = "";
      this.objet_erreur = "";
      this.montant_payer_erreur = "";
      var i = 0;

      if (this.form.date_facture === "") {
        this.date_facture_erreur = "Ce champ est obligatoire";

        i = 1;
      }
     /*  if (this.form.objet === "") {
        this.objet_erreur = "Ce champ est obligatoire";

        i = 1;
      } */

      if (this.form.montant_payer === "") {
        this.montant_payer_erreur =
          "Vous avez oublié de sélectionner le montant ";
        i = 1;
      }
      if (i == 1) return true;

      return false;
    },

    async update_reservation(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();
      formdata.append("nom_salle", this.form.nom_salle);
      formdata.append("id_user", this.form.id_user);
      formdata.append("id_direction", this.form.id_direction);

      //if(this.form.nom!==""){
      try {
        await axios.post("/reservation/update/" + id, formdata, { headers });
        showDialog6("Réservation modifié avec succès");
        bus.emit("factureAjoutee");
        const eventData = {
          editModal: false,
        };
        bus.emit("reservationDejaModifier", eventData);
      } catch (e) {
        /* console.log(e.request.status) */
        if (e.request.status === 404) {
          showDialog3("Une erreur est survenue lors de la modification");
        } else {
          showDialog3("Une erreur est survenue lors de la modification");
        }
      }
    },

    monterToupdate(reservation) {
      this.idService = service.id;
      this.editModal = service.editModal;
      this.form.nom_service = service.service;
      this.form.nom_direction = service.direction;
      this.form.id_direction = service.id_direction;
      this.form.id_user = service.id_user;
    },
  },
};
</script>
