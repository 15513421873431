<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userNom="userNom"
          :userPrenom="userPrenom"
          :userPhoto="userPhoto"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Nouvelle Facture</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a
                    class="parent-item"
                    :href="'/dashboard-saf'"
                    >Tableau de Bord</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">
                  Nouvelle Facture &nbsp;<a class="parent-item"></a
                  >&nbsp;<!-- <i
                                        class="fa fa-angle-right"></i> -->

                  <!-- <li><a class="parent-item" :href="'/service/accueil'">Service</a>&nbsp;<i
                                        class="fa fa-angle-right"></i>
                                </li> -->
                </li>
              </ol>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Information</header>
                  <button
                    id="panel-button"
                    class="mdl-button mdl-js-button mdl-button--icon pull-right"
                    data-upgraded=",MaterialButton"
                  >
                    <i class="material-icons">more_vert</i>
                  </button>
                  <ul
                    class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                    data-mdl-for="panel-button"
                  >
                    <li class="mdl-menu__item">
                      <i class="material-icons">assistant_photo</i>Action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">print</i>Another action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">favorite</i>Something else here
                    </li>
                  </ul>
                </div>

                <div class="card-body row">
                  <div class="col-lg-12 p-t-20">
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                    >
                      <label
                        class="mdl-textfield__label"
                        for="txtobjet"
                        v-show="!form.objet"
                        >Objet</label
                      >
                      <input
                        class="mdl-textfield__input"
                        type="text"
                        id="txtobjet"
                        v-model="form.objet"
                        @input="validatedata('objet')"
                      />
                      <span class="erreur">{{ this.objet_erreur }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                    >
                      <label
                        class="mdl-textfield__label"
                        for="txtFirstName"
                        v-show="!form.designation"
                        >Désignation</label
                      >
                      <input
                        class="mdl-textfield__input"
                        type="text"
                        id="txtFirstName"
                        v-model="form.designation"
                        @input="validatedata('designation')"
                      />
                      <span class="erreur">{{ this.designation_erreur }}</span>
                    </div>
                  </div>

                  <!--   <div class="col-lg-6 p-t-20">
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                    >
                      <label
                        class="mdl-textfield__label"
                        for="txtNbrJour"
                        v-show="!form.nombre_jour"
                        >Nombre Jour</label
                      >
                      <input
                        class="mdl-textfield__input"
                        type="number"
                        id="txtNbrJour"
                        v-model="form.nombre_jour"
                        @input="validatedata('nombre_jour')"
                      />
                      <span class="erreur">{{ this.nombre_jour_erreur }}</span>
                    </div>
                  </div> -->
                  <div class="col-lg-6 p-t-20">
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width"
                    >
                      <label
                        for="list3"
                        class="mdl-textfield__label"
                        v-show="!form.nombre_jour"
                        >Nombre Jour</label
                      >
                      <select
                        class="mdl-textfield__input"
                        id="list3"
                        readonly
                        tabIndex="-1"
                        v-model="form.nombre_jour"
                        @change="validatedata('nombre_jour')"
                      >
                        <option :value="1">1</option>
                        <option :value="2">2</option>
                        <option :value="3">3</option>
                        <option :value="4">4</option>
                        <option :value="5">5</option>
                        <option :value="6">6</option>
                        <option :value="7">7</option>
                        <option :value="8">8</option>
                        <option :value="9">9</option>
                        <option :value="10">10</option>
                        <option :value="11">11</option>
                        <option :value="12">12</option>
                        <option :value="13">13</option>
                        <option :value="14">14</option>
                        <option :value="15">15</option>
                        <option :value="16">16</option>
                        <option :value="17">17</option>
                        <option :value="18">18</option>
                        <option :value="19">19</option>
                        <option :value="20">20</option>
                      </select>
                      <span class="erreur">{{ this.nombre_jour_erreur }}</span>
                    </div>
                  </div>

                  <div class="col-lg-6 p-t-20">
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                    >
                      <label
                        class="mdl-textfield__label"
                        for="txtMontantjour"
                        v-show="!form.montant_jour"
                        >Montant Jour</label
                      >
                      <select
                        class="mdl-textfield__input"
                        id="txtMontantjour"
                        readonly
                        tabIndex="-1"
                        v-model="form.montant_jour"
                        @change="validatedata('montant_jour')"
                      >
                        <option :value="1">50000</option>
                        <option :value="2">100000</option>
                        <option :value="3">150000</option>
                        
                        
                      </select>
                      <span class="erreur">{{ this.montant_jour_erreur }}</span>
                    </div>
                  </div>

                  <div class="col-lg-6 p-t-20">
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                    >
                      <label
                        class="mdl-textfield__label"
                        for="dateOfBirth"
                        v-show="!form.date_location"
                        >Date Délivrance</label
                      >
                      <flat-pickr
                        v-model="form.date_location"
                        class="mdl-textfield__input"
                        @change="validatedata('date_location')"
                      ></flat-pickr>
                      <span class="erreur">{{
                        this.date_location_erreur
                      }}</span>
                    </div>
                  </div>

                  <div class="col-lg-12 p-t-20 mt-1">
                    <button
                      v-show="this.partenaire === false"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 mr-4 btn-success"
                      @click.prevent="this.createPartenaire()"
                    >
                      Nouveau partenaire
                    </button>
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width"
                      v-if="!this.partenaire"
                    >
                      <label
                        class="mdl-textfield__label"
                        for="partenaireSelect"
                        v-show="!form.id_partenaire"
                      >
                        Partenaire
                      </label>

                      <select
                        class="mdl-textfield__input"
                        id="partenaireSelect"
                        readonly
                        tabIndex="-1"
                        v-model="form.id_partenaire"
                        @change="validatedata('id_partenaire')"
                      >
                        <option
                          v-for="(partenaire, index) in partenaires"
                          :value="partenaire.id"
                          :key="index"
                        >
                          {{ partenaire.nom_partenaire }}
                        </option>
                      </select>

                      <span class="erreur">{{ id_partenaire_erreur }}</span>
                    </div>
                  </div>

                  <div class="card card-box" v-show="this.partenaire">
                    <div class="card-head">
                      <header>Information du Partenaire</header>
                    </div>

                    <div class="col-lg-12 p-t-20">
                      <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                      >
                        <label
                          class="mdl-textfield__label"
                          for="txtNamePartenaire"
                          v-show="!form.nom_partenaire"
                          >Nom Partenaire</label
                        >
                        <input
                          class="mdl-textfield__input"
                          type="text"
                          id="txtNamePartenaire"
                          v-model="form.nom_partenaire"
                          @input="validatedata('nom_partenaire')"
                        />
                        <span class="erreur">{{
                          this.nom_partenaire_erreur
                        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12 p-t-20">
                      <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                      >
                        <label
                          class="mdl-textfield__label"
                          for="txtDescription"
                          v-show="!form.description"
                          >Description</label
                        >
                        <input
                          class="mdl-textfield__input"
                          type="text"
                          id="txtDescription"
                          v-model="form.description"
                          @input="validatedata('description')"
                        />
                        <span class="erreur">{{
                          this.description_erreur
                        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12 p-t-20">
                      <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                      >
                        <label
                          class="mdl-textfield__label"
                          for="text5"
                          v-show="!form.contact"
                          >Numero Tel</label
                        >
                        <input
                          class="mdl-textfield__input"
                          type="text"
                          pattern="-?[0-9]*(\.[0-9]+)?"
                          id="text5"
                          v-model="form.contact"
                          @input="validatedata('contact')"
                        />
                        <span class="erreur">{{ this.contact_erreur }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12 p-t-20">
                      <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                      >
                        <label
                          class="mdl-textfield__label"
                          for="designation2"
                          v-show="!form.adresse"
                          >Adresse</label
                        >
                        <input
                          class="mdl-textfield__input"
                          type="text"
                          id="designation2"
                          v-model="form.adresse"
                          @input="validatedata('adresse')"
                        />
                        <span class="erreur">{{ this.adresse_erreur }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12 p-t-20">
                      <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                      >
                        <label
                          class="mdl-textfield__label"
                          for="txtemail"
                          v-show="!form.email"
                          >Email</label
                        >
                        <input
                          class="mdl-textfield__input"
                          type="mail"
                          id="txtemail"
                          v-model="form.email"
                          @input="validatedata('email')"
                        />
                        <span class="erreur">{{ this.email_erreur }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12 p-t-20">
                      <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                      >
                        <label
                          class="mdl-textfield__label"
                          for="txtpostal"
                          v-show="!form.boite_postale"
                          >Boite Postal</label
                        >
                        <input
                          class="mdl-textfield__input"
                          type="text"
                          id=" if (this.editModal) {"
                          v-model="form.boite_postale"
                          @input="validatedata('boite_postale')"
                        />
                        <span class="erreur">{{
                          this.boite_postale_erreur
                        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12 p-t-20">
                      <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                      >
                        <label
                          class="mdl-textfield__label"
                          for="dateOfBirth"
                          v-show="!form.date_debut"
                          >Date début</label
                        >
                        <flat-pickr
                          v-model="form.date_debut"
                          class="mdl-textfield__input"
                          @input="validatedata('date_debut')"
                        ></flat-pickr>
                        <span class="erreur">{{ this.date_debut_erreur }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12 p-t-20">
                      <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                      >
                        <label
                          class="mdl-textfield__label"
                          for="dateOfBirth"
                          v-show="!form.date_fin"
                          >Date fin</label
                        >
                        <flat-pickr
                          v-model="form.date_fin"
                          class="mdl-textfield__input"
                          @input="validatedata('date_fin')"
                        ></flat-pickr>
                        <span class="erreur">{{ this.date_fin_erreur }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12 p-t-20">
                      <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width"
                      >
                        <label
                          for="list3"
                          class="mdl-textfield__label"
                          v-show="!form.type"
                          >Type</label
                        >
                        <select
                          class="mdl-textfield__input"
                          id="list3"
                          readonly
                          tabIndex="-1"
                          v-model="form.type"
                          @change="validatedata('type')"
                        >
                          <option value="Secteur Privé">Secteur Privé</option>
                          <option value="Secteur Public">Secteur Public</option>
                          <option value="Ecole">Ecole</option>
                          <option value="ONG">ONG</option>
                        </select>
                        <span class="erreur">{{ this.type_erreur }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12 p-t-20">
                      <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width"
                      >
                        <label
                          for="list3"
                          class="mdl-textfield__label"
                          v-show="!form.exoneration"
                          >Exoneration</label
                        >
                        <select
                          class="mdl-textfield__input"
                          id="list3"
                          readonly
                          tabIndex="-1"
                          v-model="form.exoneration"
                          @change="validatedata('exoneration')"
                        >
                          <option value="1">OUI</option>
                          <option value="0">NON</option>
                        </select>
                        <span class="erreur">{{
                          this.exoneration_erreur
                        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12 p-t-20 mt-1">
                      <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width"
                      >
                        <label
                          class="mdl-textfield__label"
                          for="partenaireSelect"
                          v-show="!form.id_direction"
                        >
                          Direction
                        </label>

                        <select
                          class="mdl-textfield__input"
                          id="partenaireSelect"
                          readonly
                          tabIndex="-1"
                          v-model="form.id_direction"
                          @change="validatedata('id_direction')"
                        >
                          <option
                            v-for="(direction, index) in directions"
                            :value="direction.id"
                            :key="index"
                          >
                            {{ direction.nom_direction }}
                          </option>
                        </select>

                        <span class="erreur">{{ id_direction_erreur }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 p-t-20 text-center">
                    <button
                      type="submit"
                      v-if="!this.editModal"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                      @click.prevent="validerAvantAjout()"
                    >
                      Enregistrer
                    </button>

                    <button
                      type="submit"
                      v-if="this.editModal"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                      @click.prevent="validerAvantAjout()"
                    >
                      Modifier
                    </button>

                    <button
                      type="button"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                      @click="goBack()"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <appafterContent />
    </div>
    <appfooter />
  </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import Swal from "sweetalert2";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";
import router from "../../router.js";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    flatPickr,
  },
  props: ["service"],
  name: "createServiceCompenent",

  data() {
    return {
      factures: [],
      partenaires: [],
      directions: [],
      form: new Form({
        designation: "",
        nombre_jour: "",
        montant_jour: "",
        date_location: "",
        id_partenaire: "",
        id_salle: "",
        id_user: "",
        id_direction: "",
        objet: "",

        nom_partenaire: "",
        description: "",
        contact: "",
        adresse: "",
        email: "",
        boite_postale: "",
        date_debut: "",
        date_fin: "",
        type: "",
        exoneration: "",
      }),
      designation_erreur: "",
      nombre_jour_erreur: "",
      montant_jour_erreur: "",
      date_location_erreur: "",
      id_partenaire_erreur: "",
      id_salle_erreur: "",
      objet_erreur: "",

      nom_partenaire_erreur: "",
      description_erreur: "",
      contact_erreur: "",
      adresse_erreur: "",
      email_erreur: "",
      boite_postale_erreur: "",
      date_debut_erreur: "",
      date_fin_erreur: "",
      id_direction_erreur: "",
      type_erreur: "",
      exoneration_erreur: "",

      etatForm: false,
      editModal: false,
      idService: "",
      partenaire: false,
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  mounted() {
    this.get_partenaire();
    this.get_direction();

    bus.on("factureModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.monterToupdate(eventData.facture);
    });
  },

  methods: {
    ...mapActions(["login", "logout"]),
    async soumettre() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();
      formdata.append("designation", this.form.designation);
      formdata.append("nombre_jour", this.form.nombre_jour);
      formdata.append("montant_jour", this.form.montant_jour);
      formdata.append("date_location", this.form.date_location);
      formdata.append("id_partenaire", this.form.id_partenaire);
      formdata.append("id_salle", this.form.id_salle);
      formdata.append("objet", this.form.objet);
      formdata.append("nom_partenaire", this.form.nom_partenaire);
      formdata.append("description", this.form.description);
      formdata.append("contact", this.form.contact);
      formdata.append("adresse", this.form.adresse);
      formdata.append("email", this.form.email);
      formdata.append("boite_postale", this.form.boite_postale);
      formdata.append("date_debut", this.form.date_debut);
      formdata.append("date_fin", this.form.date_fin);
      formdata.append("id_direction", this.form.id_direction);
      formdata.append("type", this.form.type);
      formdata.append("exoneration", this.form.exoneration);
      formdata.append("id_user", this.userIdrole);

      try {
        const facture_store = await axios.post("/location/store", formdata, {
          headers,
        });
        showDialog6("Facture ajoutée avec succès");
        bus.emit("factureAjoutee;");
        this.resetForm();
        setTimeout(() => {
          window.location.href = "/liste-facture";
          /* bus.$emit('changerOngletActif', 'tab2');
          router.push({ name: 'listefacture' }); */
        }, 1500);
      } catch (e) {
        /* console.log(e.request.status) */
        if (e.request.status === 404) {
          showDialog3("Cette facture existe déjà");
        } else {
          //Swal.fire('Erreur !', 'Une erreur est survenue lors de l\'enregistrement', 'error')
          showDialog3("Une erreur est survenue lors de l'enregistrement");
        }
      }
    },

    validerAvantAjout() {
      const isVerifIdValid = this.validatedataOld();
      if (isVerifIdValid === true) {
        this.etatForm = false;
        this.editModal = false;
        console.log("erreur");
        return 0;
      } else {
        if (this.editModal === true) {
          this.etatForm = true;
          this.form.designation = this.form.designation.toUpperCase();
          /*  this.update_service(this.idService); */
          this.editModal = false;
        } else {
          this.form.designation = this.form.designation.toUpperCase();
          this.soumettre();
          this.etatForm = true;
          this.editModal = false;

          console.log("Tokkos");
        }
      }
    },
    goBack() {
      // Utilisez la méthode Vue.js $router pour revenir en arrière
      this.resetForm();
      this.$router.go(-1);
    },

    resetForm() {
      this.form.designation = "";
      this.form.nombre_jour = "";
      this.form.montant_jour = "";
      this.form.objet = "";
      this.form.date_location = "";
      this.form.id_partenaire = "";
      this.id_salle = "";

      (this.designation_erreur = ""),
        (this.nombre_jour_erreur = ""),
        (this.montant_jour_erreur = ""),
        (this.date_location_erreur = ""),
        (this.id_partenaire_erreur = ""),
        (this.id_salle_erreur = ""),
        (this.editModal = false);

      this.form.nom_partenaire = "";
      this.form.description = "";
      this.form.contact = "";
      this.form.adresse = "";
      this.form.email = "";
      this.form.boite_postale = "";
      this.form.date_debut = "";
      this.form.date_fin = "";

      this.nom_partenaire_erreur = "";
      this.description_erreur = "";
      this.contact_erreur = "";
      this.adresse_erreur = "";
      this.email_erreur = "";
      this.boite_postale_erreur = "";
      this.date_debut_erreur = "";
      this.date_fin_erreur = "";
      this.id_direction_erreur = "";
      this.type_erreur = "";
      this.objet_erreur = "";
      this.exoneration_erreur = "";
      this.partenaire = false;

      const eventData = {
        editModal: false,
      };
      bus.emit("factureDejaModifier", eventData);
    },

    verifCaratere(nom) {
      const valeur = /^[a-zA-ZÀ-ÿ\s]*$/;
      return valeur.test(nom);
    },
    validatePhoneNumber(phoneNumber) {
      // Expression régulière pour vérifier le numéro de téléphone (format simple ici)
      const phoneRegex = /^\d{9}$/; // Format : 9 chiffres
      return phoneRegex.test(phoneNumber);
    },

    validatedata(champ) {
      switch (champ) {
        case "designation":
          this.designation_erreur = "";
          // Effectuez la validation pour le champ 'nom'
          if (this.form.designation === "") {
            this.designation_erreur = "Ce champ est obligatoire";
            //this.coloration_erreur_rouge(this.nom_service_erreur);
            return true;
          }
          break;

        case "nombre_jour":
          //pour direction
          this.nombre_jour_erreur = "";
          if (this.form.nombre_jour === "") {
            this.nombre_jour_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "objet":
          //pour direction
          this.objet_erreur = "";
          if (this.form.objet === "") {
            this.objet_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;

        case "date_locations":
          //pour direction
          this.date_location_erreur = "";
          if (this.form.date_location === "") {
            this.date_location_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "montant_jour":
          //pour direction
          this.montant_jour_erreur = "";
          if (this.form.montant_jour === "") {
            this.montant_jour_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        /*  case "id_salle":
         
          this.id_salle_erreur = "";
          if (this.form.id_salle === "") {
            this.id_salle_erreur =
              "Vous avez oublié de sélectionner la salle concerné'";
            return true;
          }
          break; */

        case "id_partenaire":
          //pour direction
          this.id_partenaire_erreur = "";
          if (this.form.id_partenaire === "") {
            this.id_partenaire_erreur =
              "Vous avez oublié de sélectionner le partenaire concerné";
            return true;
          }
          break;
        case "nom_partenaire":
          //pour direction
          this.nom_partenaire_erreur = "";
          if (this.form.nom_partenaire === "" && this.partenaire) {
            this.nom_partenaire_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "description":
          //pour direction
          this.description_erreur = "";
          if (this.form.description === "" && this.partenaire) {
            this.description_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "contact":
          //pour direction
          this.contact_erreur = "";
          if (this.form.contact === "" && this.partenaire) {
            this.contact_erreur = "Ce champ est obligatoire";
            return true;
          } else if (
            !this.validatePhoneNumber(this.form.contact) &&
            this.partenaire
          ) {
            this.contact_erreur = "Le numéro de téléphone n'est pas valide";
            return true;
          }
          break;
        case "adresse":
          //pour direction
          this.adresse_erreur = "";
          if (this.form.adresse === "" && this.partenaire) {
            this.adresse_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "email":
          //pour direction
          this.email_erreur = "";
          if (this.form.email === "" && this.partenaire) {
            this.email_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "type":
          //pour direction
          this.type_erreur = "";
          if (this.form.type === "" && this.partenaire) {
            this.type_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "exoneration":
          //pour direction
          this.exoneration_erreur = "";
          if (this.form.exoneration === "" && this.partenaire) {
            this.exoneration_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "boite_postale":
          //pour direction
          this.boite_postale_erreur = "";
          if (this.form.boite_postale === "" && this.partenaire) {
            this.boite_postale_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "date_debut":
          //pour direction
          this.date_debut_erreur = "";
          if (this.form.date_debut === "" && this.partenaire) {
            this.date_debut_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "date_fin":
          //pour direction
          this.date_fin_erreur = "";
          if (this.form.date_fin === "" && this.partenaire) {
            this.date_fin_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "id_direction":
          //pour direction
          this.id_direction_erreur = "";
          if (this.form.id_direction === "" && this.partenaire) {
            this.id_direction_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;

        default:
          break;
      }
      return false;
    },

    validatedataOld() {
      this.designation_erreur = "";
      this.id_direction_erreur = "";
      this.nombre_jour_erreur = "";
      this.date_location_erreur = "";
      this.montant_jour_erreur = "";
      this.nom_partenaire_erreur = "";
      this.description_erreur = "";
      this.contact_erreur = "";
      this.adresse_erreur = "";
      this.email_erreur = "";
      this.boite_postale_erreur = "";
      this.date_debut_erreur = "";
      this.date_fin_erreur = "";
      this.type_erreur = "";
      this.exoneration_erreur = "";
      this.id_partenaire_erreur = "";
      var i = 0;

      if (this.form.designation === "") {
        this.designation_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.nombre_jour === "") {
        this.nombre_jour_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.objet === "") {
        this.objet_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.date_location === "") {
        this.date_location_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.montant_jour === "") {
        this.montant_jour_erreur = "Ce champ est obligatoire";

        i = 1;
      }

      /* if (this.form.id_salle === "" && this.partenaire) {
        this.id_salle_erreur =
          "Vous avez oublié de sélectionner la salle concernée";
        i = 1;
      } */

      if (this.form.id_partenaire === "" && !this.partenaire) {
        this.id_partenaire_erreur =
          "Vous avez oublié de sélectionner le partenaire concerné";
        i = 1;
      }

      if (this.form.nom_partenaire === "" && this.partenaire) {
        this.nom_partenaire_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.description === "" && this.partenaire) {
        this.description_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.contact === "" && this.partenaire) {
        this.contact_erreur = "Ce champ est obligatoire";
        i = 1;
      } else if (
        !this.validatePhoneNumber(this.form.contact) &&
        this.partenaire
      ) {
        this.contact_erreur = "Le numéro de téléphone n'est pas valide";
        i = 1;
      }

      if (this.form.adresse === "" && this.partenaire) {
        this.adresse_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.email === "" && this.partenaire) {
        this.email_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.boite_postale === "" && this.partenaire) {
        this.boite_postale_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.date_debut === "" && this.partenaire) {
        this.date_debut_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.date_fin === "" && this.partenaire) {
        this.date_fin_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.id_direction === "" && this.partenaire) {
        this.id_direction_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.type === "" && this.partenaire) {
        this.type_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.exoneration === "" && this.partenaire) {
        this.exoneration_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (i == 1) return true;

      return false;
    },

    async get_partenaire() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      await axios
        .get("/partenaire/index", { headers })
        .then((response) => {
          this.partenaires = response.data.partenaire;
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la recuperation des partenaires",
            "error"
          );
        });
    },

    createPartenaire() {
      this.partenaire = true;
    },
    async get_direction() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      await axios
        .get("/direction/index", { headers })
        .then((response) => {
          this.directions = response.data.direction;
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la recupération des directions",
            "error"
          );
        });
    },

    async update_service(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();
      formdata.append("nom_service", this.form.nom_service);
      formdata.append("id_user", this.form.id_user);
      formdata.append("id_direction", this.form.id_direction);

      //if(this.form.nom!==""){
      try {
        await axios.post("/service/update/" + id, formdata, { headers });
        showDialog6("Service modifié avec succès");
        bus.emit("serviceAjoutee");
        const eventData = {
          editModal: false,
        };
        bus.emit("serviceDejaModifier", eventData);
      } catch (e) {
        /* console.log(e.request.status) */
        if (e.request.status === 404) {
          showDialog3("Une erreur est survenue lors de la modification");
        } else {
          showDialog3("Une erreur est survenue lors de la modification");
        }
      }
    },

    monterToupdate(service) {
      this.idService = service.id;
      this.editModal = service.editModal;
      this.form.nom_service = service.service;
      this.form.nom_direction = service.direction;
      this.form.id_direction = service.id_direction;
      this.form.id_user = service.id_user;
    },
  },
};
</script>
