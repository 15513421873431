<template>
  <div class="page-wrapper">
    <!-- start header -->
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userPhoto="userPhoto"
          :userNom="userNom"
          :userPrenom="userPrenom"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Tableau de bord</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a
                    class="parent-item"
                    href="index.html"
                    >Home</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">Tableau de bord</li>
              </ol>
            </div>
          </div>

          <!-- start widget -->
          <div class="state-overview">
            <div class="row">
              <div class="col-xl-3 col-md-6 col-12">
                <div class="info-box bg-b-yellow">
                  <span class="info-box-icon push-bottom"
                    ><i data-feather="user-check"></i
                  ></span>
                  <div class="info-box-content">
                    <span class="info-box-text">Personnel</span>
                    <span class="info-box-number">{{ countElev }}</span>
                    <div class="progress">
                      <div
                        class="progress-bar"
                        :style="{ width: countElev + '%' }"
                      ></div>
                    </div>
                    <span class="progress-description"> Disponible </span>
                  </div>
                  <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
              </div>
              <!-- /.col -->
              <div class="col-xl-3 col-md-6 col-12">
                <div class="info-box bg-b-blue">
                  <span class="info-box-icon push-bottom"
                    ><i data-feather="user-plus"></i
                  ></span>
                  <div class="info-box-content">
                    <span class="info-box-text">Direction</span>
                    <span class="info-box-number">{{ countDirection }}</span>
                    <div class="progress">
                      <div
                        class="progress-bar"
                        :style="{ width: countDirection + '%' }"
                      ></div>
                    </div>
                    <span class="progress-description"> Disponible </span>
                  </div>
                  <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
              </div>
              <!-- /.col -->
              <div class="col-xl-3 col-md-6 col-12">
                <div class="info-box bg-b-green">
                  <span class="info-box-icon push-bottom"
                    ><i data-feather="grid"></i
                  ></span>
                  <div class="info-box-content">
                    <span class="info-box-text">Départements</span>
                    <span class="info-box-number">{{ this.countDep }}</span>
                    <div class="progress">
                      <div
                        class="progress-bar"
                        :style="{ width: countDep + '%' }"
                      ></div>
                    </div>
                    <span class="progress-description"> Disponible </span>
                  </div>
                  <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
              </div>
              <!-- /.col -->
              
             
              <div class="col-xl-3 col-md-6 col-12">
                <div class="info-box bg-b-pink">
                  <span class="info-box-icon push-bottom"
                    ><i data-feather="layers"></i
                  ></span>
                  <div class="info-box-content">
                    <span class="info-box-text">Unité de formation</span>
                    <span class="info-box-number">{{ countFiliere }}</span
                    ><span></span>
                    <div class="progress">
                      <div
                        class="progress-bar"
                        :style="{ width: countFiliere + '%' }"
                      ></div>
                    </div>
                    <span class="progress-description"> Disponible </span>
                  </div>
                  <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
              </div>
              <!-- /.col -->
            </div>
          </div>
          <!-- end widget -->
          <!-- chart start -->
          <div class="row">
            <div class="col-sm-6">
              <div class="card card-box">
                <div class="card-head">
                  <header>Recouvrement des étudiants</header>
                  <div class="tools">
                    <a
                      class="fa fa-repeat btn-color box-refresh"
                      href="javascript:;"
                    ></a>
                    <a
                      class="t-collapse btn-color fa fa-chevron-down"
                      href="javascript:;"
                    ></a>
                    <a
                      class="t-close btn-color fa fa-times"
                      href="javascript:;"
                    ></a>
                  </div>
                </div>
                <div class="card-body">
                  <div class="recent-report__chart">
                    <div id="chart1"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="card card-box">
                <div class="card-head">
                  <header>Recouvrement des Produits/Services</header>
                  <div class="tools">
                    <a
                      class="fa fa-repeat btn-color box-refresh"
                      href="javascript:;"
                    ></a>
                    <a
                      class="t-collapse btn-color fa fa-chevron-down"
                      href="javascript:;"
                    ></a>
                    <a
                      class="t-close btn-color fa fa-times"
                      href="javascript:;"
                    ></a>
                  </div>
                </div>
                <div class="card-body">
                  <div class="recent-report__chart">
                    <div id="chart2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Chart end -->
       <!--    <div class="row">
            <div class="col-lg-9 col-md-12 col-sm-12 col-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Derniers utilisateurs enregistrés</header>
                </div>

                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-hover dashboard-task-infos">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Nom</th>
                          <th>Prénom</th>
                          <th>Email</th>

                          <th>Fonction</th>
                          <th>Statut</th>
                          <th>Rating</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(utilisateur, index) in utilisateurs"
                          :key="index"
                        >
                          <td class="patient-img">
                            <img :src="getImageUrl(utilisateur.photo)" alt="" />
                          </td>
                          <td>{{ utilisateur.nom }}</td>
                          <td>{{ utilisateur.prenom }}</td>
                          <td>{{ utilisateur.email }}</td>

                          <td>{{ utilisateur.role.intitule }}</td>
                          <td>
                            {{ utilisateur.status === 1 ? "Inactif" : "Actif" }}
                          </td>
                          <td>
                            <i class="fas fa-star col-orange"></i>
                            <i class="fas fa-star col-orange"></i>
                            <i class="fas fa-star col-orange"></i>
                            <i class="fas fa-star col-orange"></i>
                            <i class="far fa-star col-orange"></i>
                          </td>
                          <td>
                            <a href="javascript:void(0)" class="tblEditBtn">
                              <i class="fa fa-pencil"></i>
                            </a>
                            <a href="javascript:void(0)" class="tblDelBtn">
                              <i class="fa fa-trash-o"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-12 col-12">
              <div class="card">
                <div class="card-body">
                  <div class="box-title">
                    <small class="pull-right small-lbl-green"
                      ><i class="far fa-arrow-alt-circle-up"></i> Good</small
                    >
                    Student Performance
                  </div>
                  <div class="mt-3">
                    <div class="stat-item">
                      <div class="h6">Overall Growth</div>
                      <b>35.80%</b>
                    </div>
                    <div class="stat-item">
                      <div class="h6">Montly</div>
                      <b>45.20%</b>
                    </div>
                    <div class="stat-item">
                      <div class="h6">Day</div>
                      <b>5.50%</b>
                    </div>
                  </div>
                  <div id="schart1"></div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="row">
        <div class="col-lg-9 col-md-12 col-sm-12 col-12">
            <div class="card-box">
                <div class="card-head">
                    <header>Derniers utilisateurs enregistrés</header>
                </div>

                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-hover dashboard-task-infos">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>Email</th>

                                    <th>Fonction</th>
                                    <th>Statut</th>
                                    <th>Notation</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(utilisateur, index) in utilisateurs"
                                    :key="index"
                                >
                                    <td class="patient-img">
                                        <img
                                            :src="
                                                getImageUrl(utilisateur.photo)
                                            "
                                            alt=""
                                        />
                                    </td>
                                    <td>{{ utilisateur.nom }}</td>
                                    <td>{{ utilisateur.prenom }}</td>
                                    <td>{{ utilisateur.email }}</td>

                                    <td>{{ utilisateur.role.intitule }}</td>
                                    <td>
                                        {{
                                            utilisateur.status === 1
                                                ? "Inactif"
                                                : "Actif"
                                        }}
                                    </td>
                                    <td>
                                        <i class="fas fa-star col-orange"></i>
                                        <i class="fas fa-star col-orange"></i>
                                        <i class="fas fa-star col-orange"></i>
                                        <i class="fas fa-star col-orange"></i>
                                        <i class="far fa-star col-orange"></i>
                                    </td>
                                    <td>
                                        <a
                                            href="javascript:void(0)"
                                            class="tblEditBtn"
                                        >
                                            <i class="fa fa-pencil"></i>
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            class="tblDelBtn"
                                        >
                                            <i class="fa fa-trash-o"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-12 col-12">
            <div class="card">
                <div class="card-body">
                    <div class="box-title">
                        <small class="pull-right small-lbl-green"
                            ><i class="far fa-arrow-alt-circle-up"></i>
                            Excellent</small
                        >
                        Performance Etudiants
                    </div>
                    <div class="mt-3">
                        <div class="stat-item">
                            <div class="h6">Croissance globale</div>
                            <b>35.80%</b>
                        </div>
                        <div class="stat-item">
                            <div class="h6">Mois</div>
                            <b>45.20%</b>
                        </div>
                        <div class="stat-item">
                            <div class="h6">Jour</div>
                            <b>5.50%</b>
                        </div>
                    </div>
                    <div id="schart1"></div>
                </div>
            </div>
        </div>
    </div>

          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="card-box">
                <div class="card-head">
                    <header>Fonctions</header>
                    <button
                        id="panel-button5"
                        class="mdl-button mdl-js-button mdl-button--icon pull-right"
                        data-upgraded=",MaterialButton"
                    >
                        <i class="material-icons">more_vert</i>
                    </button>
                    <ul
                        class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                        data-mdl-for="panel-button5"
                    >
                        <li class="mdl-menu__item">
                            <i class="material-icons">assistant_photo</i>Action
                        </li>
                        <li class="mdl-menu__item">
                            <i class="material-icons">print</i>Another action
                        </li>
                        <li class="mdl-menu__item">
                            <i class="material-icons">favorite</i>Something else
                            here
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table
                            class="table table-striped custom-table table-hover"
                        >
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Nom fonction</th>
                                    <!--  <th>Graph</th> -->
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(
                                        count, role, index
                                    ) in countRoleOccurrences()"
                                    :key="role"
                                >
                                    <td>{{ count }}</td>
                                    <td>{{ role }}</td>
                                    <!--   <td>
                                        <div :id="'sparkline' + index"></div>
                                    </td> -->
                                    <td>
                                        <a
                                            href="javascript:void(0)"
                                            class="tblEditBtn"
                                        >
                                            <i class="fa fa-pencil"></i>
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            class="tblDelBtn"
                                        >
                                            <i class="fa fa-trash-o"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Dernières Transactions</header>
                  <button
                    id="panel-button8"
                    class="mdl-button mdl-js-button mdl-button--icon pull-right"
                    data-upgraded=",MaterialButton"
                  >
                    <i class="material-icons">more_vert</i>
                  </button>
                  <ul
                    class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                    data-mdl-for="panel-button8"
                  >
                    <li class="mdl-menu__item">
                      <i class="material-icons">assistant_photo</i>Action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">print</i>Another action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">favorite</i>Something else here
                    </li>
                  </ul>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-hover" style="width: 100%">
                      <tbody>
                        <tr>
                          <th>#</th>
                          <th>Matricule</th>
                          <th>Nom</th>
                          <th>Status</th>
                          <th>Montant</th>
                          <th>Classe</th>
                          <th>Edit</th>
                        </tr>
                        <tr v-for="paiement in paiements">
                          <td class="patient-img">
                            <img
                              :src="this.getImageUrl(paiement.photo)"
                              alt=""
                            />
                          </td>
                          <td>{{ paiement.matricule }}</td>
                          <td>
                            {{ paiement.eleve_nom }} {{ paiement.eleve_prenom }}
                          </td>
                          <td
                            v-if="
                              paiement.type_formation === 'BTS ' &&
                              paiement.montant >= 70000
                            "
                          >
                            <i class="fas fa-circle col-green me-2"></i>
                          </td>
                          <td
                            v-if="
                              paiement.type_formation === 'BTS ' &&
                              paiement.montant < 70000
                            "
                          >
                            <i class="fas fa-circle col-red me-2"></i>
                          </td>
                          <td
                            v-if="
                              paiement.type_formation === 'BTI' &&
                              paiement.montant < 50000
                            "
                          >
                            <i class="fas fa-circle col-red me-2"></i>
                          </td>
                          <td
                            v-if="
                              paiement.type_formation === 'BTI' &&
                              paiement.montant >= 50000
                            "
                          >
                            <i class="fas fa-circle col-green me-2"></i>
                          </td>
                          <td>{{ paiement.montant }} F CFA</td>
                          <td>
                            {{ paiement.classe }} {{ paiement.niveau }}
                            {{ paiement.type_classe }}
                          </td>
                          <td>
                            <a
                              data-toggle="tooltip"
                              title=""
                              data-bs-original-title="Edit"
                              aria-label="Edit"
                              ><i class="fas fa-pencil-alt"></i
                            ></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- Quick Mail start  -->
            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="inbox">
                <div class="card">
                  <div class="card-body no-padding height-9">
                    <div class="inbox-body">
                      <div class="mail-list">
                        <div class="compose-mail">
                          <form method="post">
                            <div class="email-form">
                              <label for="to" class="">To:</label>
                              <input
                                type="text"
                                tabindex="1"
                                id="to"
                                class="form-control itemField"
                              />
                              <div class="compose-options">
                                <a
                                  onclick="$(this).hide(); $('#cc').parent().removeClass('hidden'); $('#cc').focus();"
                                  href="javascript:;"
                                  >Cc</a
                                >
                                <a
                                  onclick="$(this).hide(); $('#bcc').parent().removeClass('hidden'); $('#bcc').focus();"
                                  href="javascript:;"
                                  >Bcc</a
                                >
                              </div>
                            </div>
                            <div class="email-form hidden">
                              <label for="cc" class="">Cc:</label>
                              <input
                                type="text"
                                tabindex="2"
                                id="cc"
                                class="form-control itemField"
                              />
                            </div>
                            <div class="email-form hidden">
                              <label for="bcc" class="">Bcc:</label>
                              <input
                                type="text"
                                tabindex="2"
                                id="bcc"
                                class="form-control itemField"
                              />
                            </div>
                            <div class="email-form">
                              <label for="subject" class="">Subject:</label>
                              <input
                                type="text"
                                tabindex="1"
                                id="subject"
                                class="form-control itemField"
                              />
                            </div>
                            <div class="mt-4">
                              <div id="summernote"></div>
                              <input type="file" class="default" multiple />
                            </div>
                            <div class="box-footer clearfix">
                              <button
                                type="button"
                                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-primary pull-right"
                              >
                                Send <i class="fa fa-paper-plane-o"></i>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Activity feed end -->
            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="card card-box">
                <div class="card-head">
                  <header>Audits</header>
                </div>
                <div class="card-body">
                  <div class="row">
                    <ul class="docListWindow small-slimscroll-style">
                      <li v-for="audit in audits">
                        <div class="prog-avatar">
                          <img
                            :src="this.getImageUrl(audit.user.photo)"
                            alt=""
                            width="40"
                            height="40"
                          />
                        </div>
                        <div class="details">
                          <div class="title">
                            <a href="#"
                              >{{ audit.user.nom }} {{ audit.user.prenom }}</a
                            >
                            -{{ formatTime(audit.created_at) }}
                          </div>
                          <div v-show="audit.action == 'Suppression'">
                            <span class="feedLblStyle lblFileStyle">{{
                              audit.action
                            }}</span>
                            {{ audit.details }}
                            <small class="text-muted">{{
                              formatDateTime(audit.created_at)
                            }}</small>
                          </div>
                          <div v-show="audit.action == 'Creation'">
                            <span class="feedLblStyle lblTaskStyle">{{
                              audit.action
                            }}</span>
                            {{ audit.details }}
                            <small class="text-muted">{{
                              formatDateTime(audit.created_at)
                            }}</small>
                          </div>
                          <div v-show="audit.action == 'Modification'">
                            <span class="feedLblStyle lblCommentStyle">{{
                              audit.action
                            }}</span>
                            {{ audit.details }}
                            <small class="text-muted">{{
                              formatDateTime(audit.created_at)
                            }}</small>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <!-- <div class="full-width text-center p-t-10">
                            <a href="#" class="btn purple btn-outline btn-circle margin-0">View All</a>
                        </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- start new student list -->
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="card card-box">
                <div class="card-head">
                  <header>Liste Etudiant inscrit</header>
                  <div class="tools">
                    <a
                      class="fa fa-repeat btn-color box-refresh"
                      href="javascript:;"
                    ></a>
                    <a
                      class="t-collapse btn-color fa fa-chevron-down"
                      href="javascript:;"
                    ></a>
                    <a
                      class="t-close btn-color fa fa-times"
                      href="javascript:;"
                    ></a>
                  </div>
                </div>
                <div class="card-body">
                  <div class="table-wrap">
                    <div class="table-responsive">
                      <table
                        class="table display product-overview mb-30"
                        id="support_table"
                      >
                        <thead>
                          <tr>
                            <th>Photo</th>
                            <th>Matricule</th>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Classe</th>
                            <th>Montant</th>
                            <th>Année Académique</th>
                            <th>Date</th>
                            <th>Etat paiement</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="odd gradeX"
                            v-for="(inscription, index) in inscriptions"
                          >
                            <td class="patient-img">
                              <img
                                :src="getImageUrl(inscription.photo)"
                                alt="Etu"
                              />
                            </td>
                            <td>{{ inscription.matricule }}</td>
                            <td class="left">
                              {{ inscription.nom }}
                            </td>
                            <td class="left">
                              {{ inscription.prenom }}
                            </td>
                            <td class="left">
                              {{ inscription.classe }}
                            </td>
                            <td class="left">{{ inscription.montant }} F</td>
                            <td class="left">
                              {{ inscription.annee_academique }}
                            </td>
                            <td class="left">
                              {{ formatDateTime(inscription.date) }}
                            </td>
                            <td>
                              <span class="label label-sm label-success"
                                >Soldé</span
                              >
                            </td>
                            <td>
                              <a
                                class="tblEditBtn"
                                @click="openModal(inscription)"
                              >
                                <i class="fa fa-pencil"></i>
                              </a>
                              <a
                                href="javascript:void(0)"
                                class="tblDelBtn"
                                @click="deleteInscription(inscription)"
                              >
                                <i class="fa fa-trash-o"></i>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <appafterContent />
    </div>
    <appfooter />
  </div>
  <!-- end new student list -->
</template>
<script>
import bus from "../../eventBus.js";
import axios from "@/axios";
import Form from "vform";

import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
  },
  data() {
    return {
      form: new Form({
        nom: "",
        prenom: "",
        genre: "",
        adresse: "",
        telephone: "",
        email: "",
        date_naissance: "",
        lieu_naissance: "",
        nationalite: "",
        id_role: "",
        id_specialite: "",
        id_departement: "",
        id_service: "",
        type: "",
        situation_matrimoniale: "",
      }),
      utilisateurs: [],
      paiements: [],
      roles: [],
      audits: [],
      inscriptions: [],
      countDep: null,
      countElev: null,
      countDirection: null,
      countFiliere: null,
      
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
    
  },
  mounted() {
    this.fetchUtilisateurs();
    this.get_audit();
    this.get_departement();
    this.get_eleve();
    this.get_direction();
    this.get_filiere();
    this.get_paiement();
    this.getInscription();
   
    // this.fetchRoles().then(() => {
    // ... (autres actions au montage)
    //});
    /*  this.userNom= this.$store.state.userNom;
      this.userPrenom= this.$store.state.userPrenom;
      this.userIdrole= this.$store.state.userIdrole;
      this.userPhoto= this.$store.state.userPhoto; */

    /*     console.log("this.$store.state.user");
    console.log(this.$store); */
  },

  methods: {
    ...mapActions(['login', 'logout']),
    async get_paiement() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      await axios
        .get("/paiement/get_last", {headers})
        .then((response) => {
          const allpaiement = response.data.paiement;

          const formattedPaiement = allpaiement.map((paie) => {
            return {
              id: paie.id,
              id_eleve: paie.eleve.user.id,
              matricule: paie.eleve.user.matricule,
              photo: paie.eleve.user.photo,
              eleve_prenom: paie.eleve.user.prenom,
              eleve_nom: paie.eleve.user.nom,
              date_naissance: paie.eleve.user.date_naissance,
              adresse: paie.eleve.user.adresse,
              matricule: paie.eleve.user.matricule,

              type_recouvrement: paie.type_recouvrement,
              mode_paiement: paie.mode_paiement,
              reference: paie.reference,
              classe: paie.eleve.inscription
                .map((p) => p.classe.nom_classe)
                .join(", "),
              niveau: paie.eleve.inscription
                .map((p) => p.classe.niveau)
                .join(", "),
              type_classe: paie.eleve.inscription
                .map((p) => p.classe.type_classe)
                .join(", "),
              type_formation: paie.eleve.inscription
                .map((p) => p.classe.type_formation.intitule)
                .join(", "),
              annee: paie.concerner
                .map((p) => p.annee_academique.intitule)
                .join(", "),
              mois: paie.concerner.map((p) => p.mois.intitule).join(", "),
              id_annee: paie.concerner
                .map((p) => p.annee_academique.id)
                .join(", "),
              id_mois: paie.concerner.map((p) => p.mois.id).join(", "),
              montant: paie.montant,
              editModal: true,
            };
          });
          this.paiements = formattedPaiement;
          console.log(this.paiements);
          /*  this.initDataTable(); */
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la recuperation des paiements",
            "error"
          );
        });
    },
    formatDateTime(dateTime) {
      // Utilisez une fonction pour formater la date
      return this.formatDate(new Date(dateTime));
    },
    formatDate(date) {
      const day = date.getDate();
      const monthNumber = date.getMonth() + 1;
      const year = date.getFullYear();

      // Tableau des trois premières lettres des mois en français
      const monthAbbreviations = [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Juin",
        "Juil",
        "Aoû",
        "Sep",
        "Oct",
        "Nov",
        "Déc",
      ];

      // Obtenez les trois premières lettres du mois correspondant au numéro du mois
      const month = monthAbbreviations[monthNumber - 1];

      return `${day} ${month} ${year}`;
    },
    formatTime(timerecup) {
      return this.getTime(new Date(timerecup));
    },

    getTime(date) {
      const hours = date.getHours();
      const minutes = date.getMinutes();

      // Ajoutez un zéro devant les heures ou les minutes si elles sont inférieures à 10
      const formattedHours = hours < 10 ? `0${hours}` : hours;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      return `${formattedHours}:${formattedMinutes}`;
    },

    getImageUrl(url) {
      //return url ? `${window.location.origin}/storage/${url}` : "";
      return url ? `http://127.0.0.1:8000/storage/${url}` : '';
    },
   async  fetchUtilisateurs() {
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };
      // Exemple : remplacez cela par votre logique de récupération de données
      // et assurez-vous que les données sont correctement assignées à utilisateurs
      await axios
        .get("/user/index", {headers})
        .then((response) => {
          this.utilisateurs = response.data.user.slice(0, 7);
          console.log(this.utilisateurs);
        })
        .catch((error) => {
          console.error(
            "Erreur lors de la récupération des utilisateurs",
            error
          );
        });
    },
    async getInscription() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
            // Exemple : remplacez cela par votre logique de récupération de données
            // et assurez-vous que les données sont correctement assignées à utilisateurs
           await axios
                .get("/inscription/last", {headers})
                .then((response) => {
                    const allInscriptions = response.data.inscription;

                    const inscriptionVal = allInscriptions.filter(
                        (inscription) => {
                            return inscription.statut == 1;
                        }
                    );

                    const formattedInscriptionVal = inscriptionVal.map(
                        (inscrit) => {
                            return {
                                id: inscrit.id,
                                photo: inscrit.eleve.user.photo,
                                matricule: inscrit.eleve.user.matricule,
                                nom: inscrit.eleve.user.nom,
                               
                                prenom: inscrit.eleve.user.prenom,
                               
                              
                               
                                classe:
                                    inscrit.classe.type_formation.intitule +
                                    " " +
                                    inscrit.classe.nom_classe +
                                    " " +
                                    inscrit.classe.niveau +
                                    " " +
                                    inscrit.classe.type_classe,
                                annee_academique:
                                    inscrit.annee_academique.intitule,
                                
                                statut: inscrit.statut,
                               
                                date: inscrit.created_at,
                                montant: inscrit.montant,
                               
                               
                                nom_departement:
                                    inscrit.classe.unite_de_formation
                                        .departement.nom_departement,
                                nom_filiere:
                                    inscrit.classe.unite_de_formation
                                        .nom_unite_formation,
                            };
                        }
                    );
                    this.inscriptions = formattedInscriptionVal;
                })
                .catch((error) => {
                    console.error(
                        "Erreur lors de la récupération des etudiants inscrit",
                        error
                    );
                });
        },
    async get_departement() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      await axios
        .get("/departement/all", {headers})
        .then((response) => {
          this.countDep = response.data.departement.length;
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la recupération du nombre de département",
            "error"
          );
        });
    },
    async get_filiere() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      await axios
        .get("/unite_de_formation/all", {headers})
        .then((response) => {
          this.countFiliere = response.data.unite_de_formation.length;
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la recupération du nombre de filiere",
            "error"
          );
        });
    },
  /*   async get_direction() {
      await axios
        .get("/formateur/index")
        .then((response) => {
          this.countDirection = response.data.formateur.length;
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la recupération des audit",
            "error"
          );
        });
    }, */
    async get_direction() {
    // Récupérer le token depuis le local storage
    const token = localStorage.getItem('token');
    

    // Vérifier si le token existe
    if (this.token) {
        // Ajouter le token à l'en-tête d'autorisation
        const headers = { Authorization: `Bearer ${token}` };

        try {
            // Envoyer la requête GET avec l'en-tête d'autorisation
            const response = await axios.get("/direction/index", { headers });

            // Traiter la réponse
            this.countDirection = response.data.direction.length;
        } catch (error) {
            // Gérer les erreurs
            Swal.fire(
                "Erreur!",
                "Une erreur est survenue lors de la récupération des directions",
                "error"
            );
        }
    } else {
        // Si le token n'est pas disponible, rediriger l'utilisateur vers la page de connexion
        window.location.href = '/login';
    }
},

    async get_eleve() {
      
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      await axios
        .get("/user/count", { headers })
        .then((response) => {
          this.countElev = response.data.eleve.length;
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la recupération du nombre des étudiants",
            "error"
          );
        });
    },
    async get_audit() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      await axios
        .get("/audit/all", {headers})
        .then((response) => {
          this.audits = response.data.audit;
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la recupération des audit",
            "error"
          );
        });
    },

    countRoleOccurrences() {
      const roleOccurrences = {};

      this.utilisateurs.forEach((user) => {
        const roleIntitule = user.role ? user.role.intitule : "Non défini";

        if (!roleOccurrences[roleIntitule]) {
          roleOccurrences[roleIntitule] = 1;
        } else {
          roleOccurrences[roleIntitule]++;
        }
      });

      return roleOccurrences;
    }, //audit/all
  },
};
</script>
