<template>
    <div class="page-wrapper">
      <div class="page-header navbar navbar-fixed-top">
        <div class="page-header-inner">
          <appheader
            :userNom="userNom"
            :userPrenom="userPrenom"
            :userPhoto="userPhoto"
          />
        </div>
      </div>
      <div class="page-container">
        <appnavbar
          :userNom="userNom"
          :userPrenom="userPrenom"
          :userPhoto="userPhoto"
          :userIdrole="userIdrole"
          :userRole="userRole"
        />
  
        <!-- start page content -->
        <div class="page-content-wrapper">
          <div class="page-content">
            <div class="page-bar">
              <div class="page-title-breadcrumb">
                <div class="pull-left">
                  <div class="page-title">Nouvelle Réservation</div>
                </div>
                <ol class="breadcrumb page-breadcrumb pull-right">
                  <li>
                    <i class="fa fa-home"></i>&nbsp;<a
                      class="parent-item"
                      :href="'/dashboard-saf'"
                      >Tableau de Bord</a
                    >&nbsp;<i class="fa fa-angle-right"></i>
                  </li>
                  <li class="active">
                     Réservation &nbsp;<a class="parent-item"></a
                    >&nbsp;<!-- <i
                                          class="fa fa-angle-right"></i> -->
  
                    <!-- <li><a class="parent-item" :href="'/service/accueil'">Service</a>&nbsp;<i
                                          class="fa fa-angle-right"></i>
                                  </li> -->
                  </li>
                </ol>
              </div>
            </div>
  
            <div class="row">
              <div class="col-sm-12">
                <div class="card-box">
                  <div class="card-head">
                    <header>Information</header>
                    <button
                      id="panel-button"
                      class="mdl-button mdl-js-button mdl-button--icon pull-right"
                      data-upgraded=",MaterialButton"
                    >
                      <i class="material-icons">more_vert</i>
                    </button>
                    <ul
                      class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                      data-mdl-for="panel-button"
                    >
                      <li class="mdl-menu__item">
                        <i class="material-icons">assistant_photo</i>Action
                      </li>
                      <li class="mdl-menu__item">
                        <i class="material-icons">print</i>Another action
                      </li>
                      <li class="mdl-menu__item">
                        <i class="material-icons">favorite</i>Something else here
                      </li>
                    </ul>
                  </div>
  
                  <div class="card-body row">
                   
                   
                   
  
                    <div class="col-lg-12 p-t-20">
                      <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                      >
                        <label
                          class="mdl-textfield__label"
                          for="dateOfBirth"
                          v-show="!form.date_debut"
                          >Date début</label
                        >
                        <flat-pickr
                          v-model="form.date_debut"
                          class="mdl-textfield__input"
                          @input="validatedata('date_debut')"
                        ></flat-pickr>
                        <span class="erreur">{{ this.date_debut_erreur }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12 p-t-20">
                      <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                      >
                        <label
                          class="mdl-textfield__label"
                          for="dateOfBirth"
                          v-show="!form.date_fin"
                          >Date fin</label
                        >
                        <flat-pickr
                          v-model="form.date_fin"
                          class="mdl-textfield__input"
                          @input="validatedata('date_fin')"
                        ></flat-pickr>
                        <span class="erreur">{{ this.date_fin_erreur }}</span>
                      </div>
                    </div>
                    
                    <div class="col-lg-12 p-t-20 mt-1">
                      <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width"
                      >
                        <label
                          class="mdl-textfield__label"
                          for="partenaireSelect"
                          v-show="!form.id_salle"
                        >
                         Salle
                        </label>

                        <select
                          class="mdl-textfield__input"
                          id="partenaireSelect"
                          readonly
                          tabIndex="-1"
                          v-model="form.id_salle"
                          @change="validatedata('id_salle')"
                        >
                          <option
                            v-for="(salle, index) in salles"
                            :value="salle.id"
                            :key="index"
                          >
                            {{ salle.intitule }}
                          </option>
                        </select>

                        <span class="erreur">{{ id_salle_erreur }}</span>
                      </div>
                    </div>

                   
  
                    <div class="col-lg-12 p-t-20 text-center">
                      <button
                        type="submit"
                        v-if="!this.editModal"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                        @click.prevent="validerAvantAjout()"
                      >
                        Enregistrer
                      </button>
  
                      <button
                        type="submit"
                        v-if="this.editModal"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                        @click.prevent="validerAvantAjout()"
                      >
                        Modifier
                      </button>
  
                      <button
                        type="button"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                        @click="goBack()"
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <appafterContent />
      </div>
      <appfooter />
    </div>
  </template>
  <script>
  import bus from "../../eventBus";
  import axios from "@/axios";
  import Form from "vform";
  import Swal from "sweetalert2";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import appheader from "../layout/header.vue";
  import appfooter from "../layout/footer.vue";
  import appnavbar from "../layout/navbar.vue";
  import { mapState, mapActions } from "vuex";
  import appafterContent from "../layout/afterContent.vue";
  import IdleTimeoutMixin from '@/IdleTimeoutMixin.js';
  
  export default {
    mixins: [IdleTimeoutMixin],
    components: {
      appnavbar,
      appfooter,
      appheader,
      appafterContent,
      flatPickr,
    },
    props: ["saf"],
    name: "createReservationCompenent",
  
    data() {
      return {
        salles: [],
        reservations: [],
        form: new Form({
          date_reservation: "",
          id_salle: "",
          nom_salle: "",
          id_user: "",
          date_debut:null,
          date_fin:null
        }),
        salle_erreur: "",
       id_salle_erreur: "",
        date_debut_erreur: "",
        date_fin_erreur: "",
  
        etatForm: false,
        editModal: false,
        idReservation: "",
        factureId: "",
       
       
      };
    },
    computed: {
      //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
      token() {
        return this.$store.state.isLoggedIn;
      },
      userNom() {
        return this.$store.state.userNom;
      },
      userPrenom() {
        return this.$store.state.userPrenom;
      },
      userIdrole() {
        return this.$store.state.userIdrole;
      },
      userPhoto() {
        return this.$store.state.userPhoto;
      },
      userRole() {
        return this.$store.state.userRole;
      },
    },
  
    mounted() {
      //this.get_partenaire();
      this.get_salle();
  
      bus.on("reservationModifier", (eventData) => {
        this.editModal = eventData.editModal;
        this.monterToupdate(eventData.reservation);
      });
       this.factureId = this.$route.params.id;
    },
  
    methods: {
      ...mapActions(["login", "logout"]),
      async soumettre() {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        const formdata = new FormData();
       
        formdata.append("date_debut", this.form.date_debut);
        formdata.append("date_fin", this.form.date_fin);
        formdata.append("id_salle", this.form.id_salle); 
        formdata.append("id_user", this.userIdrole);
        formdata.append("id_location", this.factureId);
        
  
        try {
          const reservation_store = await axios.post("/reservation/store", formdata, {headers});
          if(reservation_store.data== 401){
            showDialog3("La salle est déjà réservée.");
          }
          else if(reservation_store.data== 402){
            showDialog3("La salle est déjà occupée pour la période demandée.");
          }else{
            showDialog6("Réservation ajoutée avec succès");
          bus.emit("reservationAjoutee;");
          this.resetForm();
          setTimeout(() => {
            window.location.href = "/liste-reservation";
          }, 1500); 
          }
   
        } catch (e) {
           //console.log(e.request.data)
          if (e.request.status === 404) {
            showDialog3("Une erreur est survenue lors de l'enregistrement");
          } 
        }
      },
  
      validerAvantAjout() {
        const isVerifIdValid = this.validatedataOld();
        if (isVerifIdValid === true) {
          this.etatForm = false;
          this.editModal = false;
          console.log("erreur");
          return 0;
        } else {
          if (this.editModal === true) {
            this.etatForm = true;
            
            this.editModal = false;
          } else {
            this.soumettre();
            this.etatForm = true;
            this.editModal = false;
  
            console.log("Tokkos");
          }
        }
      },

      goBack() {
    // Utilisez la méthode Vue.js $router pour revenir en arrière
    this.resetForm()
    this.$router.go(-1);
  },
  
      resetForm() {
        this.form.date_debut = null;
        this.form.date_fin = null;
        this.id_salle = "";
  
        this.date_debut_erreur = "",
        this.date_fin_erreur = "",
          this.id_salle_erreur = "",
  
          this.editModal = false;
  
        const eventData = {
          editModal: false,
        };
        bus.emit("reservationDejaModifier", eventData);
      },
  
      
  
      validatedata(champ) {
        switch (champ) {
          case "date_debut":
            this.date_debut_erreur = "";
            // Effectuez la validation pour le champ 'nom'
            if (this.form.date_debut === "") {
              this.date_debut_erreur = "Ce champ est obligatoire";
              //this.coloration_erreur_rouge(this.nom_service_erreur);
              return true;
            }
            break;
  
          case "date_fin":
            //pour direction
            this.date_fin_erreur = "";
            if (this.form.date_fin === "") {
              this.date_fin_erreur = "Ce champ est obligatoire'";
              return true;
            }
            break;
    
           case "id_salle":
           
            this.id_salle_erreur = "";
            if (this.form.id_salle === "") {
              this.id_salle_erreur =
                "Vous avez oublié de sélectionner la salle concerné'";
              return true;
            }
            break; 
  
          default:
            break;
        }
        return false;
      },
  
      validatedataOld() {
        this.date_debut_erreur ="";
        this.date_fin_erreur ="";
        this.id_salle_erreur ="";
        var i = 0;
  
        if (this.form.date_debut === "") {
          this.date_debut_erreur = "Ce champ est obligatoire";
  
          i = 1;
        }
        if (this.form.date_fin === "") {
          this.date_fin_erreur = "Ce champ est obligatoire";
  
          i = 1;
        }
       
         if (this.form.id_salle === "" && this.partenaire) {
          this.id_salle_erreur =
            "Vous avez oublié de sélectionner la salle concernée";
          i = 1;
        } 
        if (i == 1) return true;
  
        return false;
      },
  
      async get_user() {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        await axios
          .get("/user/getpersoadminunique", { headers })
          .then((response) => {
            this.users = response.data.user;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des membres administratifs",
              "error"
            );
          });
      },
  
      async get_salle() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios.get('/salle/index', {headers})
                .then(response => {
                    this.salles = response.data.salle
                    console.log("this.salles")
                    console.log(this.salles)
                }).catch(error => {
                    Swal.fire('Erreur!', 'Une erreur est survenue lors de la recuperation des dernières salles', 'error')
                });
        },
  
      async update_reservation(id) {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        const formdata = new FormData();
        formdata.append("nom_salle", this.form.nom_salle);
        formdata.append("id_user", this.form.id_user);
        formdata.append("id_direction", this.form.id_direction);
  
        //if(this.form.nom!==""){
        try {
          await axios.post("/reservation/update/" + id, formdata, { headers });
          showDialog6("Réservation modifié avec succès");
          bus.emit("reservationAjoutee");
          const eventData = {
            editModal: false,
          };
          bus.emit("reservationDejaModifier", eventData);
        } catch (e) {
          /* console.log(e.request.status) */
          if (e.request.status === 404) {
            showDialog3("Une erreur est survenue lors de la modification");
          } else {
            showDialog3("Une erreur est survenue lors de la modification");
          }
        }
      },
  
      monterToupdate(reservation) {
        this.idService = service.id;
        this.editModal = service.editModal;
        this.form.nom_service = service.service;
        this.form.nom_direction = service.direction;
        this.form.id_direction = service.id_direction;
        this.form.id_user = service.id_user;
      },
    },
  };
  </script>