import { mapState, mapActions } from "vuex";
import axios from "@/axios";
export default {
    data() {
        return {
            idleTimeout: null
        };
    },

    mounted() {
        this.resetIdleTimer();
        window.addEventListener('mousemove', () => this.resetIdleTimer());
        window.addEventListener('keypress', () => this.resetIdleTimer());
    },

    methods: {
        ...mapActions(['login', 'logout']),
        resetIdleTimer() {
            clearTimeout(this.idleTimeout);
            this.idleTimeout = setTimeout(() => {
                this.confirmLogout();
            }, 30 * 60 * 1000); // 30 minutes en millisecondes
        },
        confirmLogout() {
            if (localStorage.getItem('token')) {
                Swal.fire({
                    title: "Session expirée",
                    text: "Votre session a expiré en raison d'une inactivité prolongée. Voulez-vous vous déconnecter?",
                    icon: "warning",
                    /*  buttons: ["Rafraîchir la page", "Déconnexion"], */
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Oui",
                    cancelButtonText: "Non",
                }).then((result) => {
                    if (result.value) {
                        const token = localStorage.getItem('token');
                        const headers = { Authorization: `Bearer ${token}` };
                        axios.post("/logout", null, { headers });
                        localStorage.clear();
                        this.$router.push('/login');
                    } else {
                        location.reload();
                    }
                });
            }

        },
    },
    beforeDestroy() {
        window.removeEventListener('mousemove', this.resetIdleTimer);
        window.removeEventListener('keypress', this.resetIdleTimer);
    }
};
