<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userPhoto="userPhoto"
          :userNom="userNom"
          :userPrenom="userPrenom"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <!-- start page content -->
        <div class="page-content" v-if="!this.editModal">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Liste des Factures</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a
                    class="parent-item"
                    :href="'/dashboard-saf'"
                    >Accueil</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a class="parent-item" :href="'/facture-index'">Facture</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">Liste des factures</li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="tabbable-line">
               <!--  <ul class="nav customtab nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a href="#tab1" class="nav-link active" data-bs-toggle="tab"
                      >Proforma</a
                    >
                  </li>
                  <li class="nav-item">
                    <a href="#tab2" class="nav-link" data-bs-toggle="tab"
                      >Définitive</a
                    >
                  </li>
                  <li class="nav-item">
                    <a href="#tab3" class="nav-link" data-bs-toggle="tab"
                      >Acompte</a
                    >
                  </li>

                  <li class="nav-item">
                    <a href="#tab4" class="nav-link" data-bs-toggle="tab"
                      >Solde</a
                    >
                  </li>
                </ul> -->
                <ul class="nav customtab nav-tabs" role="tablist">
  <li class="nav-item">
    <a href="#tab1" class="nav-link" :class="{ 'active': ongletActif === 'tab1' }" data-bs-toggle="tab" @click="ongletActif = 'tab1'">Proforma</a>
  </li>
  <li class="nav-item">
    <a href="#tab2" class="nav-link" :class="{ 'active': ongletActif === 'tab2' }" data-bs-toggle="tab" @click="ongletActif = 'tab2'">Définitive</a>
  </li>
  <li class="nav-item">
    <a href="#tab3" class="nav-link" :class="{ 'active': ongletActif === 'tab3' }" data-bs-toggle="tab" @click="ongletActif = 'tab3'">Acompte</a>
  </li>
  <li class="nav-item">
    <a href="#tab4" class="nav-link" :class="{ 'active': ongletActif === 'tab4' }" data-bs-toggle="tab" @click="ongletActif = 'tab4'">Solde</a>
  </li>
</ul>
                <div class="tab-content">
                  <div class="tab-pane fontawesome-demo" :class="{ 'active': ongletActif === 'tab1'}" id="tab1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Proforma</header>
                            <div class="tools">
                              <a
                                class="fa fa-repeat btn-color box-refresh"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-collapse btn-color fa fa-chevron-down"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-close btn-color fa fa-times"
                                href="javascript:;"
                              ></a>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6 col-sm-6 col-6">
                                <div class="btn-group">
                                  <a
                                    :href="'/create-facture'"
                                    id="addRow"
                                    class="btn btn-primary"
                                  >
                                    Ajouter
                                    <i class="fa fa-plus text-white"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="example47" style="width: 100%">
                              <thead>
                                <tr>
                                  <th class="text-right">#</th>
                                  <th class="text-right">N° Facture</th>
                                  <th class="text-right">DÉSIGNATION</th>
                                  <th class="text-right">OBJET</th>
                                  <th class="text-right">PARTENAIRE</th>
                                  <th class="text-right">QUANTITE</th>
                            <th class="text-right">PRIX UNITAIRE</th>
                                  <th class="text-right">MONTANT</th>
                                  <th class="text-right">DATE</th>
                                  <th class="text-right">STATUS</th>
                                  <th class="text-right">ACTION</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  class="odd gradeX"
                                  v-for="(facture, index) in factureProforma">
                                  <td >{{ index+1 }}</td>
                                  <td >{{ facture.id }}{{ formatDate(facture.created_at) }}</td>

                                  <td class="left">
                                    {{ facture.designation }}
                                  </td>
                                  <td class="left">
                                    {{ facture.objet }}
                                  </td>

                                  <td class="left">{{ facture.partenaire }}</td>

                                  <td class="left">
                                    {{ facture.nombre_jour }}
                                  </td>

                                  <td class="left">
                                    {{ facture.montant_jour }}
                                  </td>

                                  <td class="left">
                                    {{ facture.montant_jour  *  facture.nombre_jour }}
                                  </td>

                                  <td class="left">
                                    {{ facture.date_location }}
                                  </td>

                                  <td
                                    class="text-center align-middle"
                                    v-if="facture.reserver === 1"
                                  >
                                    <span class="fas fa-circle col-green me-2">
                                      
                                    </span>
                                  </td>
                                  <td
                                    class="text-center align-middle"
                                    v-if="facture.reserver === 0"
                                    
                                  >
                                    <span class="fas fa-circle col-red me-2">
                                      
                                    </span>
                                  </td>

                                  <td>
                                    <a class="tblEditBtn" title="Imprimer"
                                      @click="factureImprime(facture.id)">
                                      <i class="fa fa-print"></i>

                                    </a>

                                    <a class="tblEditBtn" title="Modifier"
                                      @click="openModal(facture)">
                                      <i class="fa fa-pencil"></i>
                                    </a>

                                    <a class="tblDelBtn" title="Supprimer"
                                      @click="deleteLocation(facture)">
                                      <i class="fa fa-trash-o"></i>
                                    </a>

                                    <a 
                                      class="tblDelBtn" title="Réserver"
                                      @click="reserverFacture(facture.id, facture.reserver)" >
                                      <i class="fa fa-calendar-plus-o"></i>
                                    </a>
                                    
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fontawesome-demo" id="tab2" :class="{ 'active': ongletActif === 'tab2'}">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Factures Définitives</header>
                            <div class="tools">
                              <a
                                class="fa fa-repeat btn-color box-refresh"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-collapse btn-color fa fa-chevron-down"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-close btn-color fa fa-times"
                                href="javascript:;"
                              ></a>
                            </div>
                          </div>
                          <div class="card-body">
                        
                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="dataTableFactureDefinitiveS" style="width: 100%">
                              <thead>
                                <tr>
                                  
                                  <th>#</th>
                                  <th>N° Facture</th>
                                  <th>Designation</th>
                                  <th>Salle</th>
                                  <th>Partenaire</th>
                                  <th>Nombre jours</th>
                                  <th>Montant/jour</th>
                                  <th>Montant</th>
                                  <th>Date</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  class="odd gradeX"
                                  v-for="(facture, index) in factureDefinitive">
                                  <td class="left">{{ index+1 }}</td>
                                  <td class="left">{{ facture.id }}{{ formatDate(facture.created_at) }}</td>
                                  <td class="left">
                                    {{ facture.designation }}
                                  </td>
                                  <td class="left">{{ facture.salle }}</td>
                                  <td class="left">{{ facture.partenaire }}</td>
                                  <td class="left">
                                    {{ facture.nombre_jour }}
                                  </td>
                                  <td class="left">
                                    {{ facture.montant_jour }}
                                  </td>
                                  <td class="left">
                                    {{ facture.montant_jour * facture.nombre_jour }}
                                  </td>

                                  <td class="left">
                                    {{ facture.date_facture }}
                                  </td>

                                  <td>

                                    <a class="tblEditBtn" title="Imprimer"
                                      @click="factureDefImprime(facture.id)">
                                      <i class="fa fa-print"></i>
                                    </a>

                                    <a class="tblEditBtn" title="Modifier"
                                      @click="openModal(facture)">
                                      <i class="fa fa-pencil"></i>
                                    </a>

                                    <a class="tblDelBtn" title="Supprimer"
                                      @click="deleteFacture(facture)">
                                      <i class="fa fa-trash-o"></i>
                                    </a>

                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fontawesome-demo" id="tab3" :class="{ 'active': ongletActif === 'tab3'}">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Factures Acompte</header>
                            <div class="tools">
                              <a
                                class="fa fa-repeat btn-color box-refresh"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-collapse btn-color fa fa-chevron-down"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-close btn-color fa fa-times"
                                href="javascript:;"
                              ></a>
                            </div>
                          </div>
                          <div class="card-body">
                            <!-- <div class="row">
                              <div class="col-md-6 col-sm-6 col-6">
                                <div class="btn-group">
                                  <a
                                    :href="'/utilisateur/create'"
                                    id="addRow"
                                    class="btn btn-primary"
                                  >
                                    Ajouter
                                    <i class="fa fa-plus text-white"></i>
                                  </a>
                                </div>
                              </div>
                            </div> -->
                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="initDataTableFactureAcompteS" style="width: 100%">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>N° Facture</th>
                                  <th>Designation</th>
                                  <th>Salle</th>
                                  <th>Partenaire</th>
                                  <th>Nombre jours</th>
                                  <th>Montant/jour</th>
                                  <th>Montant</th>
                                  <th>Date</th>

                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  class="odd gradeX"
                                  v-for="(facture, index) in factureAcompte"
                                >
                                  <td class="left">{{ index+1 }}</td>
                                  <td class="left">{{ facture.id }}{{ formatDate(facture.created_at) }}</td>
                                  <td class="left">
                                    {{ facture.designation }}
                                  </td>
                                  <td class="left">{{ facture.salle }}</td>
                                  <td class="left">{{ facture.partenaire }}</td>
                                  <td class="left">
                                    {{ facture.nombre_jour }}
                                  </td>
                                  <td class="left">
                                    {{ facture.montant_jour }}
                                  </td>
                                  <td class="left">
                                    {{ facture.montant_jour * facture.nombre_jour }}
                                  </td>
                                  <td class="left">
                                    {{ facture.date_facture }}
                                  </td>
                                  <td>
                                  <a class="tblEditBtn" title="Imprimer"
                                      @click="factureDefImprime(facture.id)">
                                      <i class="fa fa-print"></i>

                                    </a>

                                    <a class="tblEditBtn" title="Modifier"
                                      @click="openModal(facture)">
                                      <i class="fa fa-pencil"></i>
                                    </a>

                                    <a class="tblDelBtn" title="Supprimer"
                                      @click="deleteFacture(facture)">
                                      <i class="fa fa-trash-o"></i>
                                    </a>

                                    <a
                                      class="tblDelBtn" title="Générer Facture Solde"
                                      @click=" facture.nombre_jour *
        facture.montant_jour < facture.montant_payer ? this.erreurFactureSolde() : facturerSolde(facture)">
                                      <i class="fa fa-calendar-plus-o"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fontawesome-demo" id="tab4" :class="{ 'active': ongletActif === 'tab4'}">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Facture Solde</header>
                            <div class="tools">
                              <a
                                class="fa fa-repeat btn-color box-refresh"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-collapse btn-color fa fa-chevron-down"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-close btn-color fa fa-times"
                                href="javascript:;"
                              ></a>
                            </div>
                          </div>
                          <div class="card-body">
                            <!-- <div class="row">
                              <div class="col-md-6 col-sm-6 col-6">
                                <div class="btn-group">
                                  <a
                                    :href="'/utilisateur/create'"
                                    id="addRow"
                                    class="btn btn-primary"
                                  >
                                    Ajouter
                                    <i class="fa fa-plus text-white"></i>
                                  </a>
                                </div>
                              </div>
                            </div> -->
                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="initDataTableFactureSoldeS" style="width: 100%">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>N° Facture</th>
                                  <th>Designation</th>
                                  <th>Salle</th>
                                  <th>Partenaire</th>
                                  <th>Nombre jours</th>
                                  <th>Montant/jour</th>
                                  <th>Montant</th>
                                  <th>Date</th>

                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  class="odd gradeX"
                                  v-for="(facture, index) in factureSolde"
                                >
                                  <td class="left">{{ index+1 }}</td>
                                  <td class="left">{{ facture.id }}{{ formatDate(facture.created_at) }}</td>
                                  <td class="left">
                                    {{ facture.designation }}
                                  </td>
                                  <td class="left">{{ facture.salle }}</td>
                                  <td class="left">{{ facture.partenaire }}</td>
                                  <td class="left">
                                    {{ facture.nombre_jour }}
                                  </td>
                                  <td class="left">
                                    {{ facture.montant_jour }}
                                  </td>
                                  <td class="left">
                                    {{ facture.montant_jour  *
                                    facture.nombre_jour }}
                                  </td>

                                  <td class="left">
                                    {{ facture.date_facture }}
                                  </td>

                                  <td>
                                  <a class="tblEditBtn" title="Imprimer"
                                      @click="factureDefImprime(facture.id)">
                                      <i class="fa fa-print"></i>

                                    </a>

                                    <a class="tblEditBtn" title="Modifier"
                                      @click="openModal(utilisateur)">
                                      <i class="fa fa-pencil"></i>
                                    </a>

                                    <a class="tblDelBtn" title="Supprimer"
                                      @click="deleteFacture(utilisateur)">
                                      <i class="fa fa-trash-o"></i>
                                    </a>

                                
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="page-content-wrapper" v-show="editModal">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Modifier Facture</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a
                    class="parent-item"
                    href="'/dashboard'"
                    >Tableau de Bord</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a class="parent-item" href="'/liste-facture'"
                    >Facture</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">Modifier Facture</li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Information</header>
                  <button
                    id="panel-button"
                    class="mdl-button mdl-js-button mdl-button--icon pull-right"
                    data-upgraded=",MaterialButton"
                  >
                    <i class="material-icons">more_vert</i>
                  </button>
                  <ul
                    class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                    data-mdl-for="panel-button"
                  >
                    <li class="mdl-menu__item">
                      <i class="material-icons">assistant_photo</i>Action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">print</i>Another action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">favorite</i>Something else here
                    </li>
                  </ul>
                </div>
                <div class="card-body row">
                  <FormulaireModification />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <appafterContent />
    </div>
    <appfooter />
  </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import FormulaireModification from "./editFacture.vue";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "listeFactureCompenent",
  components: {
    FormulaireModification,
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
  },

  data() {
    return {
      form: new Form({
        designation: "",
        nombre_jour: "",
        montant_jour: "",
        date_location: "",
        id_partenaire: "",
        id_salle: "",
        id_user: "",
      }),
      factureProforma: null,
      factureDefinitive: {},
      factureSolde: {},
      factureAcompte: {},
      idUser: "",
      editModal: false,

      userEnCoursDeModification: null,
      ongletActif: 'tab1'
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  mounted() {
    this.get_facture();
    
    this.get_facture_valide();

    bus.on("factureAjoutee", () => {
      this.get_facture();
      this.get_facture_valide();
    });

    bus.on('changerOngletActif', onglet => {
      this.ongletActif = onglet;
    });

    bus.on("factureDejaModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.get_facture();
      this.get_facture_valide();
    });


  },

  methods: {
    ...mapActions(["login", "logout"]),

    initDataTable() {
      this.$nextTick(() => {

        if (!$.fn.DataTable.isDataTable("#example47")) {
          $("#example47").DataTable({
            responsive: true,
            autoWidth: true,
            language: {
              paginate: {
                first: "Premier",
                previous: "Précédent",
                next: "Suivant",
                last: "Dernier",
              },
              lengthMenu: "Afficher _MENU_ entrées",
              info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
              emptyTable: "Aucune donnée disponible dans le tableau",
              loadingRecords: "Chargement en cours...",
              zeroRecords: "Aucun enregistrement correspondant trouvé",
              infoEmpty: "Affichage de 0 à 0 sur 0 entrées",
              search: "Recherche :",
            },
          });
        }
      });
    },

  initDataTableFactureDefinitive() {
  if (!$.fn.DataTable.isDataTable("#dataTableFactureDefinitive")) {
    $("#dataTableFactureDefinitive").DataTable({
      // Options de configuration pour la DataTable facturePerforma
    });
  }
},

initDataTableFactureAcompte() {
  if (!$.fn.DataTable.isDataTable("#initDataTableFactureAcompte")) {
    $("#initDataTableFactureAcompte").DataTable({
      // Options de configuration pour la DataTable factureAcompte
    });
  }
},

initDataTableFactureSolde() {
  if (!$.fn.DataTable.isDataTable("#initDataTableFactureSolde")) {
    $("#initDataTableFactureSolde").DataTable({
      // Options de configuration pour la DataTable factureSolde
    });
  }
},
formatDate(date) {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return year + month + day;
  },

    async get_facture() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      
      await axios
        .get("/location/index", { headers })
        .then((response) => {
          const allFacture = response.data.location;

          const trieFacture = allFacture.map((facture) => {
            return {
              id:facture.id,
              editModal: true,
              designation: facture.designation,
              objet: facture.objet,
              nombre_jour: facture.nombre_jour,
              montant_jour: facture.montant_jour,
              date_location: facture.date_location,
              created_at: facture.created_at,
              partenaire: facture.partenaire.nom_partenaire,

              reserver: facture.reserver,
              id_partenaire: facture.partenaire.id,

               nom: facture.user.nom,
              prenom: facture.user.prenom, 
            };
          });
          this.factureProforma = trieFacture;
 
         this.initDataTable();
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la recupération des factures proforma",
            "error"
          );
        });
    },

    async get_facture_valide() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      await axios
        .get("/facture/index", { headers })
        .then((response) => {
          const allFacture = response.data.facture;

          const filteredDefinitive = allFacture.filter((facture) => {
            return facture.type === "Definitive";
          });
          
          const DefinitiveFacture = filteredDefinitive.map((facture) => {
            return {
           
              designation: facture.location.designation,
              id:facture.id,
              salle: facture.location.salle.intitule,
              nombre_jour: facture.location.nombre_jour,
              montant_jour: facture.location.montant_jour,
              montant_payer: facture.montant_payer,
              date_facture: facture.date_facture,
              created_at: facture.created_at,
              objet: facture.objet,
              type: facture.type,

              partenaire: facture.location.partenaire.nom_partenaire,

             /*  nom: facture.user.nom,
              prenom: facture.user.prenom,  */
            };
          });

          const filteredAcompte = allFacture.filter((facture) => {
            return facture.type === "Acompte";
          });
        
          const AcompteFacture = filteredAcompte.map((facture) => {
            return {
              designation: facture.location.designation,
              id:facture.id,
              id_location:facture.id_location,
              salle: facture.location.salle.intitule,
              nombre_jour: facture.location.nombre_jour,
              montant_jour: facture.location.montant_jour,
              montant_payer: facture.montant_payer,
              date_facture: facture.date_facture,
              created_at: facture.created_at,
              objet: facture.objet,
              type: facture.type,
              id_reservation: facture.id_reservation,

              partenaire: facture.location.partenaire.nom_partenaire,

              /* nom: facture.user.nom,
              prenom: facture.user.prenom,  */
            };
          });

          const filteredSolde = allFacture.filter((facture) => {
            return facture.type === "Solde";
          });

          const SoldeFacture = filteredSolde.map((facture) => {
            return {
              designation: facture.location.designation,
              id:facture.id,
              salle: facture.location.salle.intitule,
              nombre_jour: facture.location.nombre_jour,
              montant_jour: facture.location.montant_jour,
              montant_payer: facture.montant_payer,
              date_facture: facture.date_facture,
              created_at: facture.created_at,
              objet: facture.objet,
              type: facture.type,

             partenaire: facture.location.partenaire.nom_partenaire,

              /* nom: facture.user.nom,
              prenom: facture.user.prenom, */ 
            };
          });

          this.factureDefinitive = DefinitiveFacture;
          this.initDataTableFactureDefinitive()
         /*  console.log("this.factureDefinitive");
          console.log(this.factureDefinitive); */

          this.factureAcompte = AcompteFacture;
          this.initDataTableFactureAcompte()
        /*   console.log("this.factureAcompte");
          console.log(this.factureAcompte); */

          this.factureSolde = SoldeFacture;
          this.initDataTableFactureSolde()
         /*  console.log("this.factureSolde");
          console.log(this.factureSolde); */

        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la recupération des factures ",
            "error"
          );
        });
    },

    changement(event) {
      this.interesser = event;
    },


   

    async deleteLocation(type) {
      const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            Swal.fire({
                title: 'Êtes-vous sûr de vouloir supprimer cet enregistrement?',
                text: "Cette action sera irréversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non'
            }).then((result) => {
                if (result.value) {
                    axios.delete(`/location/delete/${type.id}`, {headers}).then(resp => {

                        showDialog6("Ligne supprimée avec succès");
                        this.get_facture();


 
                    }).catch(function (error) {
                        console.log(error);
                    })
                }
          
        });
      
    },
   
    async deleteLocation(type) {
      const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            Swal.fire({
                title: 'Êtes-vous sûr de vouloir supprimer cet enregistrement?',
                text: "Cette action sera irréversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non'
            }).then((result) => {
                if (result.value) {
                    axios.delete(`/location/delete/${type.id}`, {headers}).then(resp => {

                        showDialog6("Ligne supprimée avec succès");
                        this.get_facture();


 
                    }).catch(function (error) {
                        console.log(error);
                    })
                }
          
        });
      
    },
   
    async deleteFacture(type) {
      const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            Swal.fire({
                title: 'Êtes-vous sûr de vouloir supprimer cet enregistrement?',
                text: "Cette action sera irréversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non'
            }).then((result) => {
                if (result.value) {
                    axios.delete(`/facture/delete/${type.id}`, {headers}).then(resp => {

                        showDialog6("Ligne supprimée avec succès");
                        this.get_facture();

 
                    }).catch(function (error) {
                        console.log(error);
                    })
                }
          
        });
      
    },

    openModal(facture) {
      this.editModal = true;
      const eventData = {
        facture: facture,
        /* editModal: true */
      };
      bus.emit('factureModifier', eventData);
      console.log("etatModal set to true:", this.etatModal);
    },

    reserverFacture(factureId, reserver) {
       if(reserver === 1){
        showDialog3("Cette facture à été déjà réserver");
      }
      else{
        this.$router.push({ name: 'reservation', params: { id: factureId } });
      }
    },

    factureImprime(factureId) {
        this.$router.push({ name: 'imprimerFactureProforma', params: { id: factureId } });
    },
    factureDefImprime(factureId) {
        this.$router.push({ name: 'imprimerFactureReserver', params: { id: factureId } });
    },

    erreurFactureSolde() {
      showDialog3("Cette facture à été déjà valider");
    }, 

    async facturerSolde(facture) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();

      //formdata.append("date_facture", this.form.date_facture);
        formdata.append("montant_payer", facture.nombre_jour *
        facture.montant_jour - facture.montant_payer);

        formdata.append("id_user", this.userIdrole);
        formdata.append("objet", facture.objet);
        formdata.append("id_location", facture.id_location);
        formdata.append("id_reservation", facture.id_reservation);
        formdata.append("type", "Solde");

      try {
        const reservation_store = await axios.post("/facture/store", formdata, {
          headers,
        });
        if (reservation_store.data == 401) {
          showDialog3("Cette facture à été déjà réserver");
        } else {
          showDialog6("Facture ajoutée avec succès");
          /* bus.emit("factureAjoutee;");
          this.resetForm(); */
          setTimeout(() => {
            window.location.href = "/liste-facture";
          }, 1500);
        }
      } catch (e) {
        //console.log(e.request.data)
        if (e.request.status === 404) {
          showDialog3("Une erreur est survenue lors de l'enregistrement");
        }
      }
    },

    getImageUrl(url) {
      //return url ? `${window.location.origin}/storage/${url}` : '';
      return url ? `http://127.0.0.1:8000/storage/${url}` : "";
    },
  },
};
</script>
