import { createStore } from "vuex";
import router from './router';

export default createStore({
    state: {
        isLoggedIn: !!localStorage.getItem('token'),
       userNom: localStorage.getItem('userNom'),
       userPrenom: localStorage.getItem('userPrenom'),
       userIdrole: localStorage.getItem('userIdrole'),
       userPhoto: localStorage.getItem('userPhoto'),
       userRole: localStorage.getItem('userrole'),

       authmatricule: localStorage.getItem('authmatricule'), 
       authid: localStorage.getItem('authid'), 
       authadresse: localStorage.getItem('authadresse'), 
       authtelephone: localStorage.getItem('authtelephone'), 
       authemail: localStorage.getItem('authemail'), 
       authdate_de_naissance: localStorage.getItem('authdate_de_naissance'), 
       authlieu_de_naissance: localStorage.getItem('authlieu_de_naissance'), 
       authnationalite: localStorage.getItem('authnationalite'), 
       authstatus: localStorage.getItem('authstatus'), 
       authgenre: localStorage.getItem('authgenre'), 
       authdepartement: localStorage.getItem('authdepartement'), 
       authunite_de_formation: localStorage.getItem('authunite_de_formation'), 
       //user: JSON.parse(localStorage.getItem('user') || "{}")
    },
    mutations:{ // button (instructions)
        LOGIN(state, userNom, userPrenom, userIdrole, userPhoto, userRole, authmatricule,
            authid, authadresse, authtelephone, authemail, authdate_de_naissance, authlieu_de_naissance,
            authnationalite, authstatus, authgenre, authdepartement, authunite_de_formation){
           
            state.isLoggedIn= true; //on
            state.userNom = userNom;
            state.userPrenom = userPrenom;
            state.userIdrole = userIdrole;
            state.userPhoto = userPhoto;
            state.userRole = userRole;
            state.authmatricule= authmatricule;
            state.authid= authid;
            state.authadresse= authadresse;
            state.authtelephone= authtelephone;
            state.authemail= authemail;
            state.authdate_de_naissance= authdate_de_naissance;
            state.authlieu_de_naissance= authlieu_de_naissance;
            state.authnationalite= authnationalite;
            state.authstatus= authstatus;
            state.authgenre= authgenre;
            state.authdepartement= authdepartement;
            state.authunite_de_formation= authunite_de_formation;
        },
        LOGOUT(state){
            state.isLoggedIn= false // off
            //state.user = {};
            state.userNom = "";
            state.userPrenom = "";
            state.userIdrole = "";
            state.userPhoto = "";
            state.userRole = "";
            state.authmatricule= "";
            state.authid= "";
            state.authadresse= "";
            state.authtelephone= "";
            state.authemail= "";
            state.authdate_de_naissance= "";
            state.authlieu_de_naissance= "";
            state.authnationalite= "";
            state.authstatus="";
            state.authgenre= "";
            state.authdepartement= "";
            state.authunite_de_formation= "";
        }
    },

    actions:{ // kids
        login({commit}, userNom, userPrenom, userIdrole, userPhoto, userRole, authmatricule,
            authid, authadresse, authtelephone, authemail, authdate_de_naissance, authlieu_de_naissance,
            authnationalite, authstatus, authgenre, authdepartement, authunite_de_formation){
                
            commit('LOGIN', userNom, userPrenom, userIdrole, userPhoto, userRole, authmatricule,
            authid, authadresse, authtelephone, authemail, authdate_de_naissance, authlieu_de_naissance,
            authnationalite, authstatus, authgenre, authdepartement, authunite_de_formation)
        },
        logout({commit, dispatch}){
            commit('LOGOUT')
            dispatch('navigateToLogin')
        },
        navigateToLogin(){
            router.push({name: 'Login'});
        }
    }
});
